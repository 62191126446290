<!-- (c) 智汇考题 -->
<template>
    <div class="container">
        <template v-if="loading">
            <common-loading></common-loading>
        </template>
        <div v-else>
            <div class="navheader borderbox" :class="{pass: ispass, nopass: !ispass}">
                <img
                    class="back"
                    @click="goBack()"
                    src="../../../assets/img/back-s.png"
                />
                <div class="title white">考试成绩</div>
            </div>
            <div class="score-result borderbox" :class="{pass: ispass, nopass: !ispass}">
                <span class="score white"><strong>{{ userPaper.score }}</strong>分</span>
                <div class="pass-info">
                    <span>{{ispass ? '及格' : '未及格'}}，</span>
                    <span>{{ userPaper.passScore }}/{{ userPaper.allScore }}</span>
                </div>
                <div class="info-num">答对{{ rightCount }}/{{ questions.length }}题</div>
                <div class="info-num">用时：{{ userPaper.hr }}时{{ userPaper.min }}分{{userPaper.sec}}秒</div>
            </div>
            <div class="answers borderbox">
                <div class="answer-item borderbox" v-if="questions.length > 0">
                    <div class="numberbox">
                        <div class="num" :class="{
                            correct: item.is_correct === 1,
                            error: item.is_correct === 0,
                            act: item.is_correct === 2,
                            no: item.is_correct === 3,
                        }"
                            v-for="(item, index) in questions"
                            :key="index" @click="seeComment(index)">{{index+1}}</div>
                    </div>
                </div>
            </div>
            <div class="config-btn borderbox" @click="seeComment(0)">
                <div class="submit">查看解析</div>
            </div>
        </div>
    </div>
</template>
<script>
import CommonLoading from "../../../components/common-loading.vue";

export default{
    components: {
        CommonLoading,
    },
    data() {
        return {
            loading: true,
            id: this.$route.query.id || 6,
            pid: this.$route.query.pid || 31,
            questions: [],
            paper: null,
            userPaper: null,
            surplus: 0,
            ispass: false,
            back: this.$route.query.back || -2,
            rightCount: 0,
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            this.loading = true;
            this.$api.Exam.PaperJoinRecord(this.id, this.pid).then((res) => {
                this.paper = res.data.paper;
                this.userPaper = res.data.record;
                this.ispass = (this.userPaper.score >= this.paper.passScore) ? true : false;
                this.rightCount = res.data.rightCount;
                const end = parseInt(Date.parse(new Date(this.userPaper.submitAt.replace(/-/g, "/"))));
                const now = parseInt(Date.parse(new Date(this.userPaper.createdAt.replace(/-/g, "/"))));
                const msec = end - now;
                let day = parseInt(msec / 1000 / 60 / 60 / 24);
                let hr = parseInt((msec / 1000 / 60 / 60) % 24);
                let min = parseInt((msec / 1000 / 60) % 60);
                let sec = parseInt((msec / 1000) % 60);
                this.userPaper.hr = hr > 9 ? hr : "0" + hr;
                this.userPaper.min = min > 9 ? min : "0" + min;
                this.userPaper.sec = sec > 9 ? sec : "0" + sec;
                let normaldata = res.data.questions;
                if (normaldata.length === 0) {
                    this.$message.error("未获取到试题");
                    this.$router.push({
                        name: "AnswerExams",
                    });
                    return;
                }
                let unread = 0;
                normaldata.forEach((item) => {
                    if (!item.answer_content) {
                        unread++;
                    }
                    res.data.answers.forEach((item2) => {
                        if (item.id === item2.questionId) {
                            item.is_correct = item2.isCorrect;
                        }
                    });
                });
                this.questions = normaldata;
                this.surplus = unread;
                this.loading = false;
            });
        },
        seeComment(num) {
            this.$router.push({
                name: "AnswerExamsPlay",
                query: {
                    id: this.id,
                    pid: this.pid,
                    num: num
                },
            });
        },
        goBack() {
            this.$router.go(this.back);
        },
    }
}
</script>
<style lang="less" scoped>
    .container {
        background: #f1f2f6;
    }
    .navheader {
        border: none;
    }
    .nopass {
        background: #ff5068;
    }
    .pass {
        background: #04c877;
    }
    .white {
        color: #FFF;
    }
    .score-result {
        width: 100%;
        height: 210px;
        margin-top: 44px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .score {
            height: 48px;
            font-size: 24px;
            font-weight: 500;
            line-height: 48px;
            margin-bottom: 30px;
            strong {
                font-size: 48px;
                font-weight: 500;
            }
        }
         .pass-info {
            font-size: 18px;
            font-weight: 500;
            color: #fff;
            span {
                font-size: 18px;
                font-weight: 500;
                color: #fff;
            }
        }
        .info-num {
            height: 14px;
            font-size: 14px;
            font-weight: 400;
            color: #fff;
            line-height: 14px;
            margin-top: 20px;
        }
    }
    .answers {
        width: 100%;
        height: auto;
        margin-bottom: 70px;
        .answer-item {
            width: 100%;
            height: auto;
            padding: 20px 15px 30px 15px;
            background: #fff;
            .name {
                width: 100%;
                margin-bottom: 30px;
                height: 18px;
                font-size: 18px;
                font-family: PingFangSC-Medium,PingFang SC;
                font-weight: 500;
                color: #333;
                line-height: 18px;
            }
            .numberbox {
                width: 100%;
                height: auto;
                display: grid;
                grid-row-gap: 30px;
                grid-column-gap: 33px;
                grid-template-columns: repeat(6,minmax(0,1fr));
                .num{
                    width: 30px;
                    height: 30px;
                    background: #ccc;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 16px;
                    font-weight: 500;
                    color: #fff;
                }
                &:hover {
                    opacity: 0.8;
                }
                .correct {
                    border: none;
                    background: #04c877;
                    color: #fff;
                }
                .error {
                    border: none;
                    color: #fff;
                    background: #f63b46;
                }
                .act {
                    border: none;
                    color: #fff;
                    background: #fa8c16;
                }
                .no {
                    border: none;
                    color: #fff;
                    background: #999999;
                }
                .active {
                    border: none;
                    background: #3ca7fa;
                    color: #fff;
                }
            }
        }
    }
    .config-btn {
        width: 100%;
        height: 60px;
        padding: 5px 15px;
        position: fixed;
        bottom: 0;
        display: flex;
        flex-direction: row;
        background: #fff;
        justify-content: space-between;
        .submit{
            width: 100%;
            height: 50px;
            background: linear-gradient(315deg,#33baf7,#1784ed);
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-weight: 500;
            color: #fff;
            line-height: 16px;
        } 
    }
</style>
